import React from "react"
import { useTranslation, withTranslation } from "react-i18next";
import parse from "html-react-parser";

import Layout from "../../components/layout"
import SEO from "../../components/seo"

function NotFoundPage(){
  const { t } = useTranslation();
  return (
    <Layout>
      <div className="container vh-100 pt-5">
        <SEO title={t("seo.404.title")} />
        <h1>{t("seo.404.title")}</h1>
        <p>{parse(t("seo.404.content"))}</p>
      </div>
    </Layout>
  )
}

export default withTranslation()(NotFoundPage)
